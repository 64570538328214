import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import getSysConfig from "@/utils/httpGetConfigFile";
import { httpNPGet } from "@/utils/httpRequest";
import store from "@/store";

//注册插件router
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  //  学前必读
  {
    name: "reading",
    path: "/reading",
    component: () => import("../views/reading/reading.vue"),
  },
  {
    name: "privacyPolicy",
    path: "/privacyPolicy",
    component: () => import("../views/privacyPolicy/privacyPolicy.vue"),
  },
  {
    name: "userAgreement",
    path: "/userAgreement",
    component: () => import("../views/userAgreement/userAgreement.vue"),
  },
  //  政策法规
  {
    name: "policy",
    path: "/policy",
    component: () => import("../views/policy/policy"),
  },
  //  政策法规详情
  {
    name: "policyDetails",
    path: "/policyDetails",
    component: () => import("../views/policy/policyDetails"),
  },
  {
    path: "/Mine",
    name: "Mine",
    component: () => import("../views/mine/Mine.vue"),
    redirect: "MineCourse",
    children: [
      // 课程统计
      {
        path: "/CourseStatistics",
        name: "CourseStatistics",
        component: () => import("../views/course/CourseStatistics.vue"),
      },
      // 课程统计更多
      {
        path: "/CourseStatisticsMore",
        name: "CourseStatisticsMore",
        component: () => import("../views/course/CourseStatisticsMore.vue"),
      },
      // 个人中心首页
      {
        name: "PersonalHome",
        path: "/PersonalHome",
        component: () => import("../views/personal/PersonalHome.vue"),
      },
      // 我的课程
      {
        name: "MineCourse",
        path: "/MineCourse",
        component: () => import("../views/mine/MineCourse.vue"),
      },
      // 个人中心-公告更多页
      {
        name: "BulletinMore",
        path: "/BulletinMore",
        component: () => import("../views/course/BulletinMore.vue"),
      },
      // 个人中心-公告详情页
      {
        name: "BulletinDetails",
        path: "/BulletinDetails",
        component: () => import("../views/course/BulletinDetails.vue"),
      },
      // 个人中心-课程详情页
      {
        name: "CourseDetails",
        path: "/CourseDetails",
        component: () => import("../views/course/CourseDetails.vue"),
      },
      // 我的证书
      {
        name: "MineCert",
        path: "/MineCert",
        component: () => import("../views/mine/MineCert.vue"),
      },
      // 我的订单
      {
        name: "MineOrder",
        path: "/MineOrder",
        component: () => import("../views/mine/MineOrder.vue"),
      },
      // 发票申请
      {
        name: "BillApply",
        path: "/BillApply",
        component: () => import("../views/bill/BillApply.vue"),
      },
      // 发票选择
      {
        name: "BillSelect",
        path: "/BillSelect",
        component: () => import("../views/bill/BillSelect.vue"),
      },
      // 发票详情页
      {
        name: "Bill",
        path: "/Bill",
        component: () => import("../views/bill/Bill.vue"),
      },
      // 发票申请操作页
      {
        name: "BillHandle",
        path: "/BillHandle",
        component: () => import("../views/bill/BillHandle.vue"),
      },
      // 证书申请
      {
        name: "CertApply",
        path: "/CertApply",
        component: () => import("../views/certApply/CertApply.vue"),
      },
      // 我的设置
      // 个人资料
      {
        name: "PersonalCenter",
        path: "/PersonalCenter",
        component: () => import("../views/personal/PersonalCenter.vue"),
      },
      // 安全中心
      {
        name: "SafeCenter",
        path: "/SafeCenter",
        component: () => import("../views/safeCenter/SafeCenter.vue"),
      },
      // 绑定新手机页
      {
        name: "BindPhone",
        path: "/BindPhone",
        component: () => import("../views/bill/BindPhone.vue"),
      },
    ],
    mode: "history",
  },
  // 帮助中心
  {
    path: "/helper",
    name: "Helper",
    component: () => import("../views/helper/Helper"),
    redirect: "/helper/HotIssue",
    children: [
      // 热点问题
      {
        name: "HotIssue",
        path: "/helper/HotIssue",
        component: () => import("../views/hotIssue/HotIssue"),
      },
      // 热点问题详情页
      {
        name: "HotIssueDetails",
        path: "/helper/HotIssueDetails",
        component: () => import("../views/hotIssue/HotIssueDetails"),
      },

      // 操作指南
      {
        name: "Operation",
        path: "/helper/Operation",
        component: () => import("../views/operation/Operation"),
      },
      // 操作指南详情页
      {
        name: "OperationDetails",
        path: "/helper/OperationDetails",
        component: () => import("../views/operation/OperationDetails"),
        meta: "详情",
      },
    ],
  },
  // 联系我们
  {
    name: "ConnectUs",
    path: "/helper/ConnectUs",
    component: () => import("../views/connectUs/ConnectUs"),
  },
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login.vue"),
  },
  // 注册
  {
    path: "/enroll",
    name: "Enroll",
    component: () => import("@/views/login/enroll.vue"),
  },
  // 注册个人信息
  {
    path: "/enrollren",
    name: "Enrollren",
    component: () => import("@/views/login/enrollren.vue"),
  },
  // 修改密码
  {
    path: "/modify",
    name: "Modify",
    component: () => import("@/views/login/modify.vue"),
  },
  // 实名认证 authentication
  {
    path: "/authentication",
    name: "Authentication",
    component: () => import("@/views/authentication/authentication.vue"),
  },
  // 视频播放页
  {
    path: "/videocatalog",
    name: "Videocatalog",
    component: () => import("@/views/videoplayback/videocatalog.vue"),
  },
  // 证书申请
  {
    path: "/certificateList",
    name: "CertificateList",
    component: () =>
      import("@/views/certificateapplication/certificateList.vue"),
  },
  // 证书详情
  {
    path: "/certificateDetail",
    name: "CertificateDetail",
    component: () =>
      import("@/views/certificateapplication/certificateDetail.vue"),
  },
  // 确认订单
  {
    path: "/certificateList/orderpayment",
    name: "Confirmorder",
    component: () => import("@/views/certificateapplication/confirmorder.vue"),
  },
  // 支付页面
  {
    path: "/certificateList/orderpayment/confirmorder",
    name: "Orderpayment",
    component: () => import("@/views/certificateapplication/orderpayment.vue"),
  },
  // 支付成功
  {
    path: "/certificateList/orderpayment/confirmorder/payok",
    name: "Payok",
    component: () => import("@/views/certificateapplication/payok.vue"),
  },
  // 二维码证书页面
  {
    // path: '/certificateList/verification',
    path: "/certificate/verification",
    name: "verification",
    component: () => import("@/views/certificateapplication/verification.vue"),
  },
  // 课程中心
  {
    name: "CourseCenter",
    path: "/CourseCenter",
    component: () => import("../views/course/CourseCenter"),
  },
  // 选择课程-课程简介
  {
    name: "CourseChoosing",
    path: "/CourseChoosing",
    component: () => import("../views/course/CourseChoosing"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("vodDomainName")) {
    if (store.state.appPageConfig) {
      next();
    } else {
      let enterpriseObj = JSON.parse(localStorage.getItem("enterpriseObj"));
      httpNPGet(
        "/app/app-page-config/detail/deal?enterpriseId=" + enterpriseObj.id,
        (res) => {
          // console.log(store.state);
          store.state.appPageConfig =  res.data;
          next();
        },
        (err) => {
           ;
          throw err;
        }
      );
    }
  } else {
    getSysConfig((res) => {
      localStorage.setItem("vodDomainName", res.data.vodDomainName);
      httpNPGet(
        "/enterprise-info/find-id?id=" + res.data.enterpriseId,
        (res) => {
          // localStorage.setItem("vodDomainName", res.data.vodDomainName);
          let enterpriseObj = res.data;
          enterpriseObj.config = JSON.parse(res.data.config);
          enterpriseObj = JSON.stringify(enterpriseObj);
          // console.log(enterpriseObj);
          localStorage.setItem("enterpriseObj", enterpriseObj);
          httpNPGet(
            "/app/app-page-config/detail/deal?enterpriseId=" + res.data.id,
            (res) => {
              // console.log(store.state);
              store.state.appPageConfig =  res.data;
              next();
            },
            (err) => {
               ;
              throw err;
            }
          );
        }
      );
    });
  }
});

// 挂载路由导航守卫 -- 相当于通过token值是否为空 设置了网页的访问权限
// router.beforeEach((to, from, next) => {
//     // to 将要访问的路径
//     // from 代表从哪个路径跳转而来
//     // next 是一个函数，表示放行，可以执行
//     //   1. next()放行
//     //   2. next('/login) 【强制跳转】到login页面
//
//     /* 如果请求登录页面，就放行 */
//     if (to.path === '/login') return next()
//     /* 请求登录之后才可以访问的页面 */
//     // 获取token
//     const tokenStr = window.localStorage.getItem("oatoken")
//     // 如果token的值为空，表示没有登陆，就强制跳转到登录页面
//     if (!tokenStr) return next('/login')
//     // 如果token的值不为空，表示已经登陆了，就允许访问页面
//     next()
// })

export default router;
