<template>
    <div>
        <!--  ====== 首行 ======  -->
        <div class="header">
            <div class="container">
                <div class="header-top">
                    <div v-if="enterpriseObj">{{ '欢迎来到' + enterpriseObj.name + '专业技术人才继续教育学习平台' }}</div>
                    <div v-else>欢迎来到苏州市电子信息技师学院专业技术人才继续教育学习平台</div>
                    <div class="header-top-right">
                        <div>
                            <img src="@/assets/home/icon-eva：email.png" alt="" />
                            <span v-if="enterpriseObj">{{ enterpriseObj.email }}</span>
                            <span v-else>jixujiaoyu@edu</span>
                        </div>
                        <div>
                            <img src="@/assets/home/icon-phone.png" alt="" />
                            <span v-if="enterpriseObj">{{ enterpriseObj.mobile }}</span>
                            <span v-else>68709111</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--  ======  nav  ======  -->
        <div class="nav-f" :style="this.$route.path == '/' ? 'background-color:none;' : 'background-color:#ffffff'">
            <div class="container">
                <div class="nav1">
                    <div class="nav-left" @click="toHome">
                        <slot name="logo">
                            <img v-if="enterpriseObj" class="logo" :src="$store.getters.enterpriseObj.config.homeLogoRevert" alt="#" />
                            <img v-else class="logo" :src="$store.getters.enterpriseObj.config.homeLogo" alt="" />
                        </slot>
                        <!-- <span>|</span>
                        <h2>继续教育</h2> -->
                    </div>
                    <div class="nav-center">
                        <!-- 标题列表 -->
                        <div class="nav-list" ref="navList"
                            :style="this.$route.path == '/' ? 'color:white;' : 'color:black'">
                            <div class="nav-list-index" v-for="(item, index) in navList" :key="item"
                                @click="changeTitle(item, index)" :class="{ 'nav-list--active': index === active }">
                                <div ref="navItem" style="width: fit-content; margin: 0 auto">
                                    {{ item }}
                                </div>
                            </div>
                        </div>
                        <!-- 下划线 -->
                        <div class="nav-line">
                            <div :style="{
                                'transition-duration': historyActive != null ? '0' : '0.3s',
                                transform:
                                    'translateX(' + translateX + 'px) translateX(-50%)',
                                width: lineWidth + 'px',
                            }"></div>
                        </div>
                    </div>
                    <el-popover v-if="this.userId" placement="bottom" width="40" trigger="hover">
                        <!--            <el-button style="width: 100%;">个人中心</el-button>-->
                        <!--            <el-button style="width: 100%;">退出登录</el-button>-->
                        <div class="goGe" @click="toMine">个人中心</div>
                        <div class="goGe" @click="loginOut">退出登录</div>
                        <div slot="reference" style="display: flex;align-items: center">
                            <span :style="this.$route.path == '/' ? 'color:white;' : 'color:black'"
                                style="width: 80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; display: inline-block;">{{
                                    this.userName
                                }}</span>
                            <img v-if="this.userGender == '男'"
                                style="width: 52px;height: 52px;border-radius: 50%;overflow: hidden;margin-left: 10px"
                                src="@/assets/login/默认头像-男.png">
                            <img v-else
                                style="width: 52px;height: 52px;border-radius: 50%;overflow: hidden;margin-left: 10px"
                                src="@/assets/login/默认头像-女.png">
                        </div>
                    </el-popover>
                    <!--          <div v-if="this.userId" style="display: flex;align-items: center">-->
                    <!--            <span style="width: 80px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis; display: inline-block;">{{ this.userName }}</span>-->
                    <!--            <img v-if="this.userGender == '男'" style="width: 52px;height: 52px;border-radius: 50%;overflow: hidden;margin-left: 10px" src="@/assets/login/默认头像-男.png">-->
                    <!--            <img v-else style="width: 52px;height: 52px;border-radius: 50%;overflow: hidden;margin-left: 10px" src="@/assets/login/默认头像-女.png">-->
                    <!--&lt;!&ndash;            <p style="color: #1371E7;margin-left: 10px" @click="goExit">退出</p>&ndash;&gt;-->
                    <!--          </div>-->
                    <el-button v-else class="nav-center-btn" type="primary" @click="goLogin()">登录/注册</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { adornUrl, httpNPGet, httpPost, httpGet } from "@/utils/httpRequest";
import getSysConfig from "@/utils/httpGetConfigFile";

export default {
    name: "",
    props: {
        active: {
            type: Number
        },
    },
    data() {
        return {
            // tip
            tipShow: true,
            // nav
            navList: ["首页", "选课中心", "证书列表", "个人中心", "帮助中心"],
            translateX: 700,
            lineWidth: 32,
            historyActive: 0,
            oldHistory: "",
            userId: '',
            userName: '',
            userGender: '',
            logoImg: '',
            enterpriseObj: {}
        };
    },
    created() {
        this.historyActive = this.$route.query.oldHistory;
        this.enterpriseObj = this.$store.getters.enterpriseObj;
    },
    mounted() {
        this.logoImg = localStorage.getItem('logoImg');
        window.onresize = () => {
            return (() => {
                this.setUnderLine(this.active);
            })();
        };
        this.setUnderLine(this.historyActive);
        // 等待css渲染完成
        this.$nextTick(function () {
            // console.log(this.historyActive)
            this.historyActive = null;
            this.setUnderLine(this.active);
        });
        // console.log("route");
        this.userId = localStorage.getItem('userId')
        if (this.userId) {
            this.userName = localStorage.getItem('userName')
            this.userGender = localStorage.getItem("userGender")
        } else if (localStorage.getItem('oatoken')) {
            httpNPGet("/app/app-user/info", (res) => {
                // console.log(res.data)
                this.$store.user = res.data
                // console.log(this.$store.user)
                this.userName = res.data.name;
                this.userGender = res.data.gender;
                this.userId = res.data.userId;
                localStorage.setItem("userName", res.data.name)
                localStorage.setItem("userGender", res.data.gender)
                localStorage.setItem("userId", res.data.userId)
            }, (err) => {
                // console.log(err.msg)
                if (err.msg == "身份认证不完善") {
                    this.$message.error("身份认证不完善")
                    this.$router.push('/authentication')
                }
                throw err
            })
        }
    },
    methods: {
        /**
         * 点击切换标题
         * @param {number} index 菜单序列(点击的第index个)
         */
        toHome() {
            this.$router.push("/")
        },
        changeTitle(item, index) {
            this.oldHistory = this.active;
            // console.log(item)
            this.setUnderLine(index);
            if (item === "首页") {
                // this.$router.push('/');
                this.$router.push({
                    path: '/',
                    query: { oldHistory: this.oldHistory }
                })
            } else if (item === "选课中心") {
                this.$router.push({
                    path: '/CourseCenter',
                    query: { oldHistory: this.oldHistory }
                });
            } else if (item === "帮助中心") {
                this.$router.push({
                    path: '/helper',
                    query: { oldHistory: this.oldHistory }
                });
            } else if (item === "个人中心") {
                this.$router.push({
                    path: '/PersonalHome',
                    query: { oldHistory: this.oldHistory }
                });
            } else if (item === "证书列表") {
                this.$router.push({
                    path: '/CertificateList',
                    // CertificateList
                    query: { oldHistory: this.oldHistory }
                });
            }
        },
        /**
         * 设置下划线
         */
        setUnderLine(value) {
            // 获取屏幕宽
            // let width = document.documentElement.clientWidth;
            let width = 600;
            // 获取菜单宽度
            let navWidth = this.$refs.navList.clientWidth;
            // console.log(navWidth)
            // 每个菜单所占宽度 = 屏宽 / 菜单个数
            let slidingWidth = (navWidth / 5).toFixed(2);
            // 半个菜单宽度 （为了将下划线与菜单对齐）
            let halfSlidingWidth = (slidingWidth / 2).toFixed(2);
            // 水平位移目的坐标 = 半个菜单宽度 + (菜单宽 x 当前所在的index)
            this.translateX =
                width / 2 -
                navWidth / 2 +
                Number(halfSlidingWidth) +
                Number(slidingWidth * value);
            // console.log(this.translateX)
            let arr = [];
            for (let i in this.$refs.navItem) {
                arr.push(this.$refs.navItem[i].clientWidth);
            }
            this.lineWidth = arr[value];
            // console.log(this.oldHistory)
        },
        //  ------------------------
        goLogin() {
            this.$router.push('/login')
        },
        goExit() {
            window.localStorage.getItem('userId').clear();
            // this.$router.push('/login')
        },
        toMine() {
            this.$router.push("/PersonalHome")
        },

        loginOut() {
            httpNPGet("/app/app-user/login-out", (res) => {
                // console.log(res)
                localStorage.clear();
                location.reload()
            }, (err) => {
                 
                throw err
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.logo {
    width: 375px;
    height: 80px;
}

.container {
    width: 1200px;
    margin: 0 auto;
}

.header {
    height: 40px;
    line-height: 40px;
    background-color: #303030;
    color: aliceblue;
    font-size: 14px;

    .header-top {
        display: flex;
        justify-content: space-between;
        background-color: #303030;
        color: aliceblue;
    }

    .header-top-right,
    .header-top-right>div {
        display: flex;
        align-items: center;
    }

    .header-top-right img {
        width: 16px;
        height: 16px;
        margin: 0 10px;
    }
}

.nav {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.14);
    color: #fff !important;
}

.nav-f {
    width: 100%;
    // box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
    //position: fixed;
    //top: 0;
    //left: 0;
    // background: rgb(255, 254, 254);
    box-sizing: border-box;
    border-bottom: 2px solid rgba(187, 187, 187, 0.2);
    color: #333 !important;
    z-index: 999;
}

.goGe {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    //background-color: #409EFF;
}

.goGe:hover {
    background-color: #409EFF;
    color: #FFFFFF;
}

.nav1 {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-left {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        height: 80px;
        cursor: pointer;

        /* line-height: 80px; */
        img {
            width: 380px;
            height: 80px;
        }

        .nav-left h2 {
            font-size: 24px;
            font-family: Roboto-SemiBold, Roboto;
            font-weight: 600;
            line-height: 76px;
            letter-spacing: 2px;
        }

        .nav-left span {
            width: 1px;
            height: 26px;
            background: #ffffff;
            border-radius: 0px 0px 0px 0px;
            margin: 0 15px;
            opacity: 0.2;
        }
    }

    .nav-center {
        width: 600px;

        .nav-list {
            /* margin: 0 auto;
            max-width: 600px; */
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 78px;
            // .nav-list-index:hover{
            // color: #1e9eff;
            // font-weight: bold;
            // }
        }

        .nav-list>div {
            width: 600px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC, sans-serif;
            font-weight: 400;
            line-height: 16px;
            cursor: pointer;
            text-align: center;
            border-right: solid 0.2px #d0d0d0;
        }

        .nav-list>div:last-child {
            border: none;
        }

        .nav-list--active {
            font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
            font-weight: bold;
        }
    }

    .nav-line {
        margin: 0 auto;
    }

    .nav-line>div {
        /*width: 32px;*/
        height: 4px;
        background-color: #1371E7;
        /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
        border-radius: 2px;
        // transition-duration: 2s;
        position: relative;
        top: 0px;
    }

    .nav-center-btn {
        /* height: 40px; */
        width: 122px;
        height: 40px;
        background: #1371e7;
        border-radius: 4px 4px 4px 4px;
        border: 0;
        opacity: 1;
        font-size: 16px;
        cursor: pointer;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        outline: none;
    }


}
</style>
