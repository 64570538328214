<template>
  <div class="section-inner">
    <div class="swiper-container swiper-container-horizontal">
      <div class="swiper-wrapper">
        <!--下文中的data-swiper-parallax属性是swiper的视差效果，如果觉得污染代码，可以删除-->
        <!-- bannerNum是轮播图需要渲染多少页 -->
        <div class="swiper-slide" v-for="(pages,i) in this.bannerNum">
          <a v-for="(item,index) in banner.slice(i*4,i*4+4)" v-if="banner[index]" @click="goElective(item)" href="#">
            <div class="inner-content">
              <!-- {{ item }} -->
              <img  v-if="item.smallCover" :src="$store.getters.vodDomainName + item.smallCover" width="75">
              <img  v-if="!item.smallCover" src="@/assets/img/course.jpg" width="75">
              <h3 style="text-align: left;width: 100%;padding: 0 10px">{{ item.name }}</h3>
              <div style="width: 244px;display: flex;justify-content: space-between;padding-top: 13px">
                <div>{{item.teacherName}}</div>
                <div>
                  <img style="width: 16px;height: 16px;display: inline-block" src="@/assets/home/icon-eye.png">
                  {{ item.hits }}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>
<script>
import Swiper from 'swiper';
import {adornUrl, httpGet, httpNPGet, httpPost} from "@/utils/httpRequest";

export default {
  name: 'swiper',
  props:{
    active:{
      type:Array
    },
  },
  data() {
    return {
      electiveDate:[],
      banner:"",
      bannerNum:""
    }
  },
  created(){
    // console.log(Math.trunc(this.active.length/4))
    let num = Math.trunc(this.active.length/4)
    // console.log(this.active.length/4)
    if((this.active.length/4)%1 === 0){
      this.bannerNum = num
    }else{
      this.bannerNum = num+1
    }
    // console.log(this.bannerNum)
    this.banner = this.active
  },
  mounted() {
    var mySwiper = new Swiper('.swiper-container', {
      loop: false,
      // 如果需要分页器
      // pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    })
    mySwiper.navigation.update();
  },
  methods:{
    goElective(item) {
      this.$router.push({
        name:'CourseChoosing',
        query:{
          id: item.id
        }
      })
    },
  },
}
</script>

<style scoped>

* {
  padding: 0;
  margin: 0;
  font-family: "微软雅黑";
  box-sizing: inherit;
  outline: none;
}
:after, :before {
  box-sizing: inherit;
}
a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style: none;
}
body{
  background-color: #313131
}
.pc-bg-container {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

}
.home-market-section {
  text-align: center;
  background-color: #0057b1;
}
/*标题*/
.home-market-section .section-title {
  margin-bottom: 30px;
  font-size: 30px;
  min-width: 150px;
  text-align: left;
  color: #F8F8F8;
  display: inline-block;
  position: relative;
  border: none;
  height: 42px;
  line-height: 42px;
  padding-left: 10px;
}
.home-market-section .section-title a {
  width: 100%;
  display: block;
  position: relative;
}
.home-market-section .section-title a:before {
  position: absolute;
  content: '';
  right: -12px;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  top: 50%;
  transform: translate(0, -50%);
  transition: .2s;
}
.home-market-section .section-title a:hover:before {
  right: -16px;
}
.section-inner{
  min-width: 1200px;
}
/*内容盒子*/
.home-market-section .section-inner {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
}
.home-market-section .section-inner .swiper-container {
  height: 290px;
  max-width: 1230px;
  padding-top: 29px;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper {
  align-items: center;
  height: 279px!important;
}

/*内容列表*/
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide {
  height: 279px!important;
  background: 0 0;
  padding: 0 50px;
  box-sizing: border-box;
  text-align: center;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide>a {
  display: block;
  float: left;
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(201,164,103,.2);
  width: calc((100% - 60px)/ 4);
  /*padding: 45px 25px 0;*/
  height: 90%;
  margin-right: 20px;
  border-radius: 5px;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide>a:hover {
  box-shadow: 0 10px 40px rgba(0,0,0,0.30);
  /* border: 1px solid #1371e7; */
  -webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.30);
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide img {
  width: 100%;
  height: 155px;
  /*width: auto;*/
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide h3 {
  font-weight: normal;
  margin-top: 17px;
  font-size: 18px;
  color: #252b3a;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide p {
  margin-top: 10px;
  font-size: 14px;
  color: #a0a2a8;
  text-align: center;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide>a .time {
  position: absolute;
  bottom: 12px;
  width: calc(100% - 60px);
  text-align: center;
  /*border-top: 1px solid #e7e7e7;*/
  color: #666a75;
  padding-top: 10px;
  letter-spacing: .44px;
  line-height: 23px;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide>a .time span {
  font-size: 20px;
  color: #f66f6a;
  letter-spacing: 0;
  line-height: 23px;
  margin-right: 3px;
}
.home-market-section .section-inner .swiper-container .swiper-wrapper .swiper-slide>a:last-of-type {
  margin-right: 0;
}
/*分页*/
.home-market-section .section-inner .swiper-container .swiper-pagination-bullets {
  bottom: 50px;
}
.home-market-section .section-inner .swiper-container .swiper-pagination-bullet {
  position: relative;
  margin: 0 5px;
  width: 24px;
  height: 4px;
  border-radius: 100px;
  opacity: 1;
  background: 0 0;
}
.home-market-section .section-inner .swiper-container .swiper-pagination-bullet:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 24px!important;
  height: 4px;
  opacity: 1;
  left: 0;
  border-radius: 100px;
  background: #dfe1f1;
  vertical-align: middle;
  transition: opacity .8s, background-color .8s;
}
.home-market-section .section-inner .swiper-container .swiper-pagination-bullet-active {
  opacity: 1;
}
.home-market-section .section-inner .swiper-container .swiper-pagination-bullet-active:before {
  width: 24px!important;
  opacity: 1;
  border-radius: 100px;
  transform: scale(1.2);
  background: #38ffe3;
}
/*左右箭头*/
.home-market-section .section-inner .swiper-button-prev {
  left: 0;
  top: 118px;
  margin-top: 0;
  /*background: url(../images/marketplace-home-arrow_big.svg);*/
}
.home-market-section .section-inner .swiper-button-next {
  right: 0;
  top: 118px;
  margin-top: 0;
  /*background: url(../images/marketplace-home-arrow_big.svg);*/
  /*transform: rotate(180deg);*/
}
.home-market-section .section-inner .swiper-button-next:hover {
  /*background: url(../images/marketplace-home-arrow_big_hover.svg);*/
}
.home-market-section .section-inner .swiper-button-prev:hover {
  /*background: url(../images/marketplace-home-arrow_big_hover.svg);*/
}

.inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

</style>
