<template>
  <div id="app">
    <router-view />
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  }
}
</script>

<style>
* {
  margin: 0;
}
body{
  background-color: rgb(247, 247, 247);
}
h2 {
  margin: 0;
}

p {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /*margin-top: 60px;*/
}

::-webkit-scrollbar {
/*滚动条整体样式*/
width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
/* height: 1px; */
}
::-webkit-scrollbar-thumb {
/*滚动条里面小方块*/
border-radius: 14px;
box-shadow   : inset 0 0 5px #0970d511;
background   : #1f80f75b;
}
::-webkit-scrollbar-track {
/*滚动条里面轨道*/
box-shadow   : inset 0 0 5px #096fd511;
border-radius: 14px;
background   : #ededed;
}

* {
  margin: 0;
  padding: 0;

}

img {
  border: 0;
  vertical-align: middle;
  /*  去掉图片底部默认的3像素空白缝隙*/
}

html, body {
  height: 100%;
  width: 100%;
  /*background-image: url("assets/img/BG.png");*/
  /*background-size: cover;*/
  /*background-attachment: fixed;*/
  /*overflow: hidden;*/
}
</style>
