<template>
    <div>
        <!-- footer -->
        <div class="footer">
            <div class="footer-detail">
                <div style="width: 82.7%;">
                    <img class="code-logo" :src="$store.getters.enterpriseObj.config.homeLogo" alt="">
                    <div style="margin-top: 10px;margin-bottom: 10px;">
                        <span>主办单位：{{ $store.getters.enterpriseObj.name }}</span>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <span>地址： {{ $store.getters.enterpriseObj.address }}</span>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <span>联系电话： {{ $store.getters.enterpriseObj.mobile }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- bottom-->
        <div class="bottom">
            <div class="bottom-content">
                <div>Copyright 2023 • {{ $store.getters.enterpriseObj.name }}</div>
                <div class="bottom-right">
                    <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32050802011652" target="_blank"
                        style="color: #fff;margin-right: 20px;text-decoration:none"><span>苏公网安备 32050802011652</span></a>
                    <a style="color: #fff;text-decoration:none" href="https://beian.miit.gov.cn/"
                        target="_blank">苏ICP备11034322号-2</a>
                </div>
            </div>
        </div>
    </div>
</template>
 
<script>
export default{
    name: "footer1",
}
</script>
 
<style scoped lang='scss'>
.footer {
    padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

    .footer-content {
        width: 61.458%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 1.5rem auto;
        padding: 2rem 0 0 0;
        align-items: center;

        .footer-top-line-1 {
            width: inherit;
            height: 0;
            opacity: 0.4;
            border: 0.0625rem solid;
            border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
        }

        .footer-top-line-2 {
            width: inherit;
            height: 0;
            opacity: 0.4;
            border: 0.0625rem solid;
            border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
            transform: rotate(180deg);
        }

        .footer-top-line-font {
            margin: 0 1.1%;
            font-size: 0.75rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.47);
            line-height: 1.25rem;
            width: 70%;
        }

        @media screen and (max-width: 1539px) {
            .footer-top-line-font {
                width: 120%;
            }
        }

        @media screen and (max-width: 1400px) {
            .footer-top-line-font {
                width: 130%;
            }
        }
    }

    .footer-detail {
        width: 1200px;
        //background-color: red;
        // display: flex;
        justify-content: space-between;
        margin: 0 auto;
        text-align: left;
        font-size: 0.875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.25rem;
        align-items: center;

        .line {
            width: 1px;
            height: 0.875rem;
            background: #FFFFFF;
            opacity: 0.2;
        }

        .code-logo {
            width: 35.87%;
        }

        .code-img {
            // width: 100%;
            height: 5.9375rem;
        }

        ul {
            list-style-type: none;
            margin-top: 1.75rem;
            display: flex;

            justify-content: space-between;

        }
    }
}

.bottom {
    width: 100%;
    height: 2.75rem;
    background: #181B20;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.75rem;

    .bottom-content {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .bottom-right {
        display: flex;
        align-items: center;
    }

    .bottom-right::before {
        content: "";
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        background: url('~@/assets/img/pre/bradge.png');
        background-size: 100% 100%;
        margin-right: 3px;
    }
}
</style>