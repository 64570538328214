import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    appPageConfig: null
  },
  getters: {
    enterpriseObj() {
        return JSON.parse(localStorage.getItem("enterpriseObj"));
    },
    vodDomainName() {
      return localStorage.getItem("vodDomainName");
    },
    userObj() {

    },
    oatoken() {
      return localStorage.getItem("oatoken");
    }
  }
});

//创建并导出store
export default store;
