<!-- 全局弹框组件 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
    :fullscreen="fullscreen"
    :append-to-body="appendBody"
    :before-close="beforeClose"
  >
    <slot></slot>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    title: {
      default: () => {
        return "标题";
      },
    },
    visible: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    fullscreen: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    appendBody: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    width: {
      type: [String, Number],
      default: () => {
        return "50%";
      },
    },
  },
  data() {
    return {};
  },
  // 计算属性
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 方法集合
  methods: {
    beforeClose(val) {
      if (this.$listeners["before-close"]) {
        // 如果提供执行
        this.$emit("before-close", val);
      } else {
        // 否侧执行其他
        this.$emit("update:visible", false);
      }
    },
  },
  beforeDestroy() {}, // 生命周期 - 销毁之前
};
</script>
<style scoped>
::v-deep .el-dialog__body{
  padding: 24px 0 0 0;
}
::v-deep .el-dialog__headerbtn .el-dialog__close{
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

::v-deep .el-dialog__header {
  text-align: left;
  height: 24px;
  font-size: 17px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  padding: 16px;
  border-bottom: 1px solid #EDEFF2;;
}
</style>
