<template>
    <div class="home" style="height: 100%;" v-if="showres">
        <!-- ======  悬浮提示信息  ====== -->
        <div @click="toReading" v-if="PagConfig.preschoolCompulsoryShowStatus == 1"
            style="position: fixed;top: 46%;left: 0px;width: 20px;background-color: #409eff;padding: 15px 7px;color: #fff;border-radius: 0 15px 15px 0; z-index: 9999; cursor: pointer;">
            学前必读
        </div>
        <div @click="toReading" v-else-if="PagConfig.preschoolCompulsoryShowStatus == 2"
            style="position: fixed;top: 46%;right: 0px;width: 20px;background-color: #409eff;padding: 15px 7px;color: #fff;border-radius:  15px 0 0 15px  ; z-index: 9999; cursor: pointer;">
            学前必读
        </div>
        <div v-else-if="PagConfig.preschoolCompulsoryShowStatus == 3 && promptShow" id="prompt" ref="prompt"
            style="position: fixed; z-index: 2000; left: 0px; top: 50%;" v-show="promptShow" @mouseover="clearFdAd"
            @mouseout="starFdAd" @click="toReading">
            <div id="prompt-content">
                <img class="prompt-img" src="@/assets/home/bg-xqbd.png" alt="" />
                <div class="prompt-pre" style="margin: auto;">
                    学前必读
                </div>
                <div class="prompt-hover">
                    <div>
                        专业技术人才 <br>继续教育 <br>
                    </div>
                    <div>
                        学 前 必 读
                    </div>
                </div>
            </div>
            <span class="prompt-close" @click="promptShow = false">
                <i class="el-icon-close"></i>
            </span>
        </div>
        <shift-box v-for="(item, index) in PagConfig.shiftBoxConfigJsonDtoList" v-if="item.isShow"
            :isShow.sync="item.isShow" :shiftData="item" :index="index"></shift-box>
        <topNav :active="pageIndex" :historyActive="$route.params.oldHistory" name=“topNav”>
            <template v-slot:logo>
                <img v-if="enterpriseObj.config" class="logo" :src="enterpriseObj.config.homeLogo" alt="#" />
                <img v-else class="logo" src="@/assets/home/icon-LOGO.png" alt="#" />
            </template>
        </topNav>
        <!--  ====== banner ======  -->
        <div class="swiperStop" @mouseover="swiperPlayEnd()" @mouseleave="swiperBegin()" @click="imgPush()">
        </div>

        <div class="banner" >
            <div class="mainSwiper"  v-if="PagConfig.swiperList.length>0" v-for="(item, index) in PagConfig.swiperList"
                :key="index"
                :style="swiperIndex == index ? 'display:block;' : 'display:none'">
                <!-- <a :href="item.toUrl"> -->
                    <img :src="item.imgUrl">
                <!-- </a> -->
            </div>
                <div class="mainSwiper" v-if="!PagConfig.swiperList.length>0"
                :key="index"
                :style="swiperIndex == index ? 'display:block;' : 'display:none'">
                <a :href="item.toUrl">
                    <img :src="item.imgUrl">
                </a>
            </div>
            <!--  ====== 步骤流程 ======  -->
            <div class="steps1">
                <div class="container">
                    <div>
                        <div class="steps" >
                            <div style="position: relative;">
                                <div class="steps-top">
                                    <div class="steps-items">
                                        <img src="../assets/home/icon-zcdlN.png" alt="" />
                                        <span>注册登录</span>
                                    </div>
                                    <img src="../assets/home/icon-jt.png" alt="" />
                                    <div class="steps-items">
                                        <img src="../assets/home/icon-xkxxN.png" alt="" />
                                        <span>选课学习</span>
                                    </div>
                                    <img src="../assets/home/icon-jt.png" alt="" />
                                    <div class="steps-items">
                                        <img src="../assets/home/icon-wcxsN.png" alt="" />
                                        <span>完成学时</span>
                                    </div>
                                    <img src="../assets/home/icon-jt.png" alt="" />
                                    <div class="steps-items">
                                        <img src="../assets/home/icon-zssqN.png" alt="" />
                                        <span>证书申请</span>
                                    </div>
                                    <img src="../assets/home/icon-jt.png" alt="" />
                                    <div class="steps-items">
                                        <img src="../assets/home/icon-jfdyN.png" alt="" />
                                        <span>下载证书</span>
                                    </div>
                                </div>
                                <div class="steps-num-left"></div>
                                <div class="steps-num-right"></div>
                                <div class="steps-num">
                                    <div class="steps-num-item">第一步</div>
                                    <div class="steps-num-item">第二步</div>
                                    <div class="steps-num-item">第三步</div>
                                    <div class="steps-num-item">第四步</div>
                                    <div class="steps-num-item">第五步</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <router-view></router-view>
        <div v-for="(item, index) in courseList" :key="index">
            <!-- ======  公共课  ====== -->
            <div class="courses" v-if="item.exhibitionStyle == 1">
                <div>
                    <div class="elective-line">
                        <div class="elective-title">
                            <img :src="item.icon" alt="" style="width: 1.625rem;height: 1.625rem;margin-right: 0.5rem;">
                            {{ item.courseType.name }}
                        </div>
                        <div class="elective-more" style="cursor:pointer;" @click="goCoursesYe()">
                            查看更多>>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="openCourses-all">
                        <div class="openCourses" style="cursor:pointer; "
                            v-for="(item, index) in item.courseInfoDTOList.slice(0, 1)" v-if="item" :key="index"
                            @click="goCourses(item)">
                            <img :src="$store.getters.vodDomainName + item.bigCover" alt="" />
                            <div class="ying"></div>
                            <div class="openCourses-content">
                                <h3 style="text-align: left">{{ item.name }}</h3>
                                <span class="openCourses-content-time" style="text-align: left">{{ item.created }}</span>
                                <span class="openCourses-content-btn">查看课程详情</span>
                            </div>
                        </div>
                        <div class="openCourses-content-item">
                            <div class="openCourses-content-item-one" style="cursor:pointer; "
                                v-for="(item, index) in item.courseInfoDTOList.slice(1, 7)" v-if="item" :key="index"
                                @click="goCourses(item)">
                                <img v-if="item.smallCover" :src="$store.getters.vodDomainName + item.smallCover" alt="" />
                                <div class="openCourses-content-item-all">
                                    <h4
                                        style="text-align: left;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;">
                                        {{ item.name }}</h4>
                                    <span style="text-align: left">{{ item.created }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ======  选修课  ====== -->
            <div class="elective-course" v-else-if="item.exhibitionStyle == 2">
                <div style="padding-bottom: 1rem;padding: 0px 20px;" class="elective-line">
                    <div class="elective-title">
                        <!--          <i :class="item.icon"></i>-->
                        <img :src="item.icon" alt="" style="width: 1.625rem;height: 1.625rem;margin-right: 0.5rem;">
                        {{ item.courseType.name }}
                    </div>
                    <div class="elective-more" style="cursor:pointer; " @click="goElectiveYe()">
                        查看更多>>
                    </div>
                </div>
                <div class="pc-bg-container">
                    <div class="home-market-section">
                        <my-swiper :active="item.courseInfoDTOList"></my-swiper>
                        <!-- <div style="height: 280px;" v-if="!item.courseInfoDTOList[0].id" v-loading="true">
                        </div> -->
                    </div>
                </div>
            </div>
            <!--  ====== 专业课 ====== -->
            <div class="profess" v-else-if="item.exhibitionStyle == 3">
                <div class="profess-line">
                    <div class="profess-title">
                        <!--          <i :class="item.icon"></i>-->
                        <img :src="item.icon" alt="" style="width: 1.625rem;height: 1.625rem;margin-right: 0.5rem;">
                        {{ item.courseType.name }}
                    </div>
                    <div class="profess-more" style="cursor:pointer; " @click="goProfessYe()">
                        查看更多>>
                    </div>
                </div>
                <div class="profess-content">
                    <div v-for="(item, i) in item.courseInfoDTOList.slice(0, 6)" v-if="item" :key="i"
                        style="cursor:pointer; " @click="goProfess(item)" class="profess-content-div">
                        <div class="profess-left">
                            <img v-if="item.smallCover" :src="$store.getters.vodDomainName + item.smallCover" alt="">
                            <!-- <div class="title">{{ item.name }}</div> -->
                        </div>
                        <div class="profess-right">
                            <div class="profess-right-title">
                                {{ item.name }}
                            </div>
                            <div class="profess-right-content" v-if="item.details"
                                v-html="item.details.replace(/<[^>]+>/g, '')">
                            </div>
                            <div class="profess-right-hour">
                                {{ item.creditHours }}课时
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 培训公告-政策法规 -->
        <div class="message" v-if="newsList.length>0">
            <div class="notice" >
                <div class="notice-line" v-if="this.newsList.length > 0">
                    <div class="notice-title">
                        <!--            <i :class="this.newsList[0].newsType.icon" ></i>-->
                        <img :src="newsList[0].newsType.icon" alt=""
                            style="width: 1.625rem;height: 1.625rem;margin-right: 0.5rem;">
                        {{ this.newsList[0].newsType.name }}
                    </div>
                    <div class="notice-more" style="cursor:pointer; " @click="goPoliceYe(newsList[0].newsType)">查看更多>>
                    </div>
                </div>
                <div class="notice-content" style="cursor:pointer; "
                    v-if="newsList.length>0"
                    v-for="(item, index) in newsList[0].newsInfoList.slice(0, 1)"
                    :key="index" @click="goPolice(item)">
                    <div class="notice-content-title">{{ item.title }}</div>
                    <div class="notice-content-item" v-if="item.content" v-html="item.content.replace(/<[^>]+>/g, '')">
                    </div>
                    <div class="notice-content-bottom">
                        <div class="notice-content-time">{{ item.created }}</div>
                        <!-- <div class="notice-content-l"></div> -->
                        <div class="notice-content-person">{{ item.hits }}人</div>
                    </div>
                </div>

                <div v-show="false">
                    {{ title }}
                </div>
                <div class="notice-item" v-if="newsList.length>0">
                    <ul v-for="(item, index) in newsList[0].newsInfoList.slice(1, 5)"
                        v-if="newsList[0].newsInfoList.length > 0" :key="index">
                        <li style="cursor:pointer; padding: 0 10px;" @click="goPolice(item)">{{ item.title }}</li>
                        <!-- {{ title.name }} -->
                    </ul>
                </div>
            </div>
            <div class="police" v-if="this.newsList.length > 1">
                <div class="police-line">
                    <div class="police-title" v-if="this.newsList[1].newsType">
                        <!--<i :class="this.newsList[1].newsType.icon"></i>-->
                        <img :src="newsList[1].newsType.icon" alt=""
                            style="width: 1.625rem;height: 1.625rem;margin-right: 0.5rem;">
                        {{ this.newsList[1].newsType.name }}
                    </div>
                    <div class="police-more" style="cursor:pointer; " @click="goPoliceYe(newsList[0].newsType)">查看更多>>
                    </div>
                </div>
                <div class="police-two-div">
                    <div class="police-left">
                        <div class="police-content" style="cursor:pointer; "
                            v-for="(item, index) in newsList[1].newsInfoList.slice(0, 1)"
                            v-if="newsList[1].newsInfoList.length > 0" :key="index" @click="goPolice(item)">
                            <div class="police-content-title">{{ item.title }}</div>
                            <div class="police-content-item" v-if="item.content"
                                v-html="item.content.replace(/<[^>]+>/g, '')"></div>
                            <div class="police-content-bottom">
                                <div class="police-content-time">{{ item.created }}</div>
                                <div class="police-content-person">{{ item.hits }}人</div>
                            </div>
                        </div>
                        <div class="police-item">
                            <ul v-for="(item, index) in newsList[1].newsInfoList.slice(1, 4)"
                                v-if="newsList[1].newsInfoList.length > 0" :key="index">
                                <li style="cursor:pointer; " @click="goPolice(item)">{{ item.title }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="police-right" style="cursor:pointer; "
                        v-for="(item, index) in newsList[1].newsInfoList.slice(0, 1)"
                        v-if="newsList[1].newsInfoList.length > 0" :key="index" @click="goPolice(item)">
                        <img :src="item.cover" alt="">
                        <div class="police-right-content">
                            <div class="title">
                                {{ item.title }}
                            </div>
                            <div class="address">
                                苏州市人力资源和社会保障局
                            </div>
                            <div class="more" style="cursor:pointer; ">
                                查看更多
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div style="height: 280px;" v-if="!this.newsList.length > 0" v-loading="true">
            </div> -->
        </div>
        <!-- 友情链接-->
        <div class="link" v-if="PagConfig.friendshipLinkList">
            <div class="link-line">
                <div class="link-title">
                    友情链接
                </div>
            </div>
            <!-- 内容 -->
            <div class="content">
                <div @click="contentUrl(item.toUrl)" class="content-div" v-for="(item, i) in PagConfig.friendshipLinkList"
                    :key="i">
                    <img :src="item.imgUrl" alt="#">
                    <span>{{ item.name }}</span>
                </div>
            </div>
        </div>
        <!-- 帮助中心 -->
        <div class="helper" v-if="PagConfig.helpShowFlag">
            <div class="helper-line">
                <div class="helper-title" style="color: aliceblue;">
                    帮助中心
                </div>
                <div class="helper-more" @click="goHelperYe()">
                    查看更多>>
                </div>
            </div>
            <!-- 内容 -->
            <div class="helper-content">
                <img style="cursor: pointer;" @click="questionClick" src="@/assets/home/common-question.png">
                <img style="cursor: pointer;" @click="fingerpostClick" src="@/assets/home/do.png">
            </div>
        </div>

        <footerBottom></footerBottom>

        <div class="news-data" v-show="closeNewsValue">
            <div class="news-shade">
                <div class="news-main">
                    <h2>{{ newsData.title }}</h2>
                    <div style="margin-top: 20px;">
                        <span v-html="newsData.content"></span>
                        <el-button @click="closeNews" type="primary" style="margin: 10px 0;">已阅读</el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swiper from "@/components/Swiper/index.vue"
import { httpNPGet, httpGet, httpPost } from "@/utils/httpRequest";
import topNav from "@/components/nav/nav.vue"
import '@wangeditor/editor/dist/css/style.css'
import footerBottom from '@/components/footer/footer'
import { info, log } from "console";
import ShiftBox from "../components/shiftBox/ShiftBox.vue";

export default {
    name: "Login",
    data() {
        return {
            userId: '',
            navList: ["首页", "选课中心", "帮助中心", "个人中心", "联系我们"],
            active: 0,
            translateX: 700,
            lineWidth: 32,
            imgUrl: ["../assets/be-首屏.png", "../assets/be-首屏.png"],
            // 浮动框
            xPos: document.body.clientWidth - 500,
            yPos: 100,
            xin: true,
            yin: true,
            step: 1,
            delay: 20,
            height: 0,
            heightSet: 0,
            widthSet: 0,
            yon: 0,
            xon: 0,
            pause: true,
            promptShow: true,
            navStatus: false,
            pageIndex: 0,
            // 友情链接
            linkItem: [
                require('@/assets/home/logo_1.png'),
                require('@/assets/home/logo_1.png'),
                require('@/assets/home/logo_1.png'),
                require('@/assets/home/logo_1.png')],
            newCoueses: [],
            newProfess: [],
            newNotice: [],
            newPolice: [],
            PagConfig: {
                swiperList:[
                    {
                        imgUrl:"https://sdy-continue-education.oss-cn-shanghai.aliyuncs.com/img/be-%E9%A6%96%E5%B1%8F%20%281%29.png",
                        name: '',
                        toUrl: '',
                    }
                ]
            },
            newsList: [
                // {
                //     newsType:"",
                //     newsInfoList:[
                //         {
                //         }
                //     ]
                // },
                // {
                //     newsType:"",
                //     newsInfoList:[
                //         {
                //         }
                //     ]
                // }
            ],
            newsTitleFirst: {},
            newsTitleSecond: {},
            courseList: "",
            courseMsg: [],
            course: [],
            title: "",
            test: [],
            swiperImgs: [],
            swiperIndex: "0",
            timer: null,
            showres: true,
            enterpriseObj: {},
            newsData: {},
            closeNewsValue: false,
        };
    },
    components: {
        mySwiper: Swiper,
        topNav: topNav,
        ShiftBox,
        footerBottom
    },
    computed: {},
    created() {
        // console.log("--------");
        // console.log(this.$store.getters);
        // console.log("--------");
        this.enterpriseId = this.$store.getters.enterpriseObj.id;
        //获取用户id
        this.userId = localStorage.getItem('userId')
        //检查用户是否登录
        // if (this.userId) {
        //     this.enterpriseId = localStorage.getItem('enterpriseId');
        //     if ('null' === this.enterpriseId) {
        //         this.enterpriseId = undefined;
        //     }
        // }
        // //用户未登录
        // else {
        //     //获取路径是否带有企业id
        //     this.enterpriseId = this.$route.query.enterpriseId;
        // }
        // console.log(this.$store.state.appPageConfig);
         // JSON.parse>>普通对象
        this.PagConfig = this.$store.state.appPageConfig;
        // console.log(this.PagConfig)
        // 公告弹框
        this.getAlert(this.PagConfig.ejectNewsConfigJsonDto)
        // 获取(咨询信息)请求
        this.getNewsList()
        // 获取(课程信息)请求
        this.getCourseList()
        // 首页轮播入口
        this.mainSwiper();
        if (this.PagConfig.preschoolCompulsoryShowStatus == 3) {
            this.starFdAd();
        }
        // this.getPagConfig(this.enterpriseId)
        // console.log("_________________________")
        // console.log(this.PagConfig.courseList[0])
        // console.log("_________________________")
    },
    mounted() {
        const that = this
        window.onscroll = function () {
            if (window.scrollY > 40) {
                that.navStatus = true
                return
            }
            that.navStatus = false
        }
        // this.getCourses();
        this.getProfess();
        // this.interval = setInterval(this.changePos, 20);
    },
    destroyed() {
        this.changePosFlag = false;
    },
    methods: {
        // 友情链接跳转
        contentUrl(url) {
            window.open(url)
        },
        // 常见问题跳转
        questionClick() {
            this.$router.push("/helper")
        },
        // 操作指南跳转
        fingerpostClick() {
            this.$router.push("/helper")
        },
        // 首页学前必读跳转
        toReading() {
            this.$router.push("/reading")
        },
        // 首页轮播图跳转
        imgPush() {
            if(this.PagConfig.swiperList[this.swiperIndex].toUrl!=""){
                window.location = this.PagConfig.swiperList[this.swiperIndex].toUrl;
            }
        },
        // 获取页面配置信息
        getPagConfig(enterpriseId) {
            // console.log(enterpriseId)
            httpGet("/app/app-page-config/detail/deal",
                {
                    enterpriseId: enterpriseId
                },
                (res) => {
                    // this.showres = true;
                    // 移动处理
                    if (res.data.shiftBoxConfigJsonDtoList && res.data.shiftBoxConfigJsonDtoList.length > 0) {
                        res.data.shiftBoxConfigJsonDtoList.forEach(v => {
                            v.isShow = true;
                        });
                    }
                    res.data.courseList.forEach((index, i) => {
                        //根据[课程Ids]数组长度创建一个(拥有长度的新数组)————存储[课程详情]数组
                        index.courseInfoDTOList = [];
                        for (let j = 0; j < 5; j++) {
                            index.courseInfoDTOList[j] = { name: 2 };
                        }
                    });
                    // JSON.parse>>普通对象
                    this.PagConfig = res.data
                    // console.log(this.PagConfig)
                    // 公告弹框
                    this.getAlert(this.PagConfig.ejectNewsConfigJsonDto)
                    // 获取(咨询信息)请求
                    this.getNewsList()
                    // 获取(课程信息)请求
                    this.getCourseList()
                    // 首页轮播入口
                    this.mainSwiper();
                    if (this.PagConfig.preschoolCompulsoryShowStatus == 3) {
                        this.starFdAd();
                    }
                }, (err) => {

                    throw err
                })
        },
        // 首页主轮播图
        mainSwiper() {
            // console.log(this.PagConfig.swiperList)
            // 首页[轮播图路径]赋值
            this.imgSwipers = this.PagConfig.swiperList
            this.swiperBegin()
        },
        // 开始轮播
        swiperBegin() {
            this.swiperPlayEnd()
            this.timer = setInterval(this.swiperPlay, 3000);
        },
        // 停止轮播
        swiperPlayEnd() {
            clearInterval(this.timer);
        },
        // 轮播图主函数
        swiperPlay() {
            if (this.swiperIndex >= this.imgSwipers.length - 1) {
                this.swiperIndex = 0;
            } else {
                this.swiperIndex++;
            }
        },
        // 首页咨询弹框
        getAlert(data) {
            // console.log(Date.now() < data.expirationTime)
            if (Date.now() < data.expirationTime) {
                this.newsData = data.newsInfo
                this.closeNewsValue = true
                // httpGet("/news-info/detail",
                //     {
                //         newsId: data.newsId
                //     },
                //     (res) => {
                //         console.log(res.data)
                //         this.newsData = res.data
                //         this.closeNewsValue = true
                //     },
                //     (err) => {
                //         // console.log(err)
                //         throw err
                //     })
            }

        },
        // 关闭首页咨询弹框
        closeNews() {
            this.closeNewsValue = false
        },
        // 根据咨询id获取咨询信息
        getNewsList() {
            //创建咨询信息列表
            this.newsList = this.PagConfig.newsList
            // 遍历咨询信息列表
            // this.newsList.forEach((index, i) => {
            //     // 咨询信息{标题}/咨询信息[详情]
            //     index.newsListTitle = " "
            //     index.newsListDetails = []
            //     console.log(index)
            //     // 请求(咨询信息)标题
            //     httpNPGet("/news-type/get/id/" + index.newsTypeId,
            //         (res) => {
            //             console.log(res)
            //             // 刷新Vue绑定
            //             this.title = res.data
            //             index.newsListTitle = this.title
            //         }, (err) => {
            //             // console.log(err)
            //             throw err
            //         })
            //     index.newsIds.forEach((item) => {
            //         // 请求(咨询信息)详情
            //         httpGet("/news-info/detail", {
            //             newsId: item
            //         },
            //             (res) => {
            //                 // [咨询信息]
            //                 index.newsListDetails.push(res.data)
            //             }, (err) => {
            //                 // console.log(err)
            //                 throw err
            //             }
            //         )
            //     })
            //     console.log(this.newsList)
            // })
        },
        // 根据课程ID获取课程类型
        getCourseList() {
            // httpNPGet("/course-type/get/id/"+)
            // console.log(this.PagConfig.courseList)
            let start = new Date().getTime();
            this.courseList = this.PagConfig.courseList
            // this.courseList.forEach((index, i) => {
            //     console.log(index)
            //     // //根据[课程Ids]数组长度创建一个(拥有长度的新数组)————存储[课程详情]数组
            //     // index.courseInfoDTOList = new Array(this.courseList[i].courseIds.length);
            //     // for (let j = 0; j < this.courseList[i].courseIds.length; j++) {
            //     //     index.courseInfoDTOList[j] = {name: 2};
            //     // }
            //     // debugger
            //     index.courseTypeList = " ";
            //     // 请求{课程类型}列表
            //     httpNPGet("/course-type/get/id/" + index.courseTypeId,
            //         (res) => {
            //             console.log(res.data)
            //             this.title = res.data
            //             index.courseTypeList = this.title
            //         }, (err) => {
            //             // console.log(err)
            //             throw err
            //         })
            //     httpGet("/course-info/basic-msg",
            //         {
            //             courseIds: index.courseIds.toString()
            //         }, (res) => {
            //             console.log(new Date());
            //             console.log(new Date().getTime() - start)
            //             console.log(res.data)
            //             // console.log(index.courseInfoDTOList)
            //             for (let i = 0; i < res.data.length; i++) {
            //                 this.$set(index.courseInfoDTOList, i, res.data[i]);
            //                 // index.courseInfoDTOList[i] = res.data[i];
            //                 // console.log(res.data[i]);
            //             }
            //             console.log(index)
            //             console.log(index.courseInfoDTOList)
            //             // debugger
            //             // index.courseInfoDTOList = res.data
            //         })
            //     // 遍历(课程Ids)请求(课程详情)
            //     // index.courseIds.forEach((item, j) => {
            //     //     httpNPGet("/course-info/detail?infoId=" + item,
            //     //         (res) => {
            //     //             this.title = res.data
            //     //             // http请求有快慢之分，将当前请求根据索引号放入[课程详情]数组
            //     //             index.courseInfoDTOList.splice(j, 1, this.title)
            //     //         }, (err) => {
            //     //             // console.log(err)
            //     //             throw err
            //     //         }
            //     //     )
            //     // })
            //     console.log(index)
            // })
            // console.log(this.courseList)
        },
        // 位移
        changePos() {
            if (this.changePosFlag) {
                requestAnimationFrame(this.changePos);
            } else {
                return;
            }
            //屏宽
            let width = document.documentElement.clientWidth;
            //屏高
            let height = document.documentElement.clientHeight;
            //获取元素高度
            this.heightSet = this.$refs.prompt.clientHeight;
            // console.log(this.$refs.prompt)
            //获取元素宽度
            this.widthSet = this.$refs.prompt.clientWidth;

            // 滚动部分跟随屏幕滚动
            // this.$refs.prompt.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
            // this.$refs.prompt.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";

            // 滚动部分不随屏幕滚动
            this.$refs.prompt.style.left =
                this.xPos + document.body.scrollLeft + "px";
            this.$refs.prompt.style.top = this.yPos + document.body.scrollTop + "px";

            if (this.yon) {
                this.yPos = this.yPos + this.step;
            } else {
                this.yPos = this.yPos - this.step;
            }
            if (this.yPos < 0) {
                this.yon = 1;
                this.yPos = 0;
            }
            if (this.yPos >= height - this.heightSet) {
                this.yon = 0;
                this.yPos = height - this.heightSet;
            }

            if (this.xon) {
                this.xPos = this.xPos + this.step;
            } else {
                this.xPos = this.xPos - this.step;
            }
            if (this.xPos < 0) {
                this.xon = 1;
                this.xPos = 0;
            }
            if (this.xPos >= width - this.widthSet) {
                this.xon = 0;
                this.xPos = width - this.widthSet;
            }
        },
        //鼠标移入效果
        clearFdAd() {
            this.changePosFlag = false;
            // clearInterval(this.interval);
            // document.getElementsByClassName('prompt-img')[0].style.display = 'none';
            // document.getElementsByClassName('prompt-pre')[0].style.display = 'none';
            // document.getElementsByClassName('prompt-hover')[0].style.display = 'block';
        },
        //鼠标移出效果
        starFdAd() {
            this.changePosFlag = true;
            requestAnimationFrame(this.changePos);
            // this.interval = setInterval(this.changePos, this.delay);
            // document.getElementsByClassName('prompt-img')[0].style.display = 'block';
            // document.getElementsByClassName('prompt-pre')[0].style.display = 'block';
            // document.getElementsByClassName('prompt-hover')[0].style.display = 'none';
        },
        goLogin() {
            this.$router.push('/enroll')
        },
        getCourses() {
            // httpNPGet("/course_info/list", (data) => {
            //   console.log('0000000000000000', data.data.records)
            //   this.newCoueses = data.data.records;
            // })
            // httpGet("/course-info/list",
            //     {
            //         typeParentId: 1
            //     }
            //     , (res) => {
            //         this.newCoueses = res.data.records;
            //     }, (err) => {
            //         // console.log(err)
            //         throw err
            //     })
        },
        goCoursesYe() {
            this.$router.push({ name: 'CourseCenter' })
        },
        getProfess() {
            // httpNPGet("/course_info/list", (data) => {
            //   console.log('0000000000000000', data.data.records)
            //   this.newCoueses = data.data.records;
            // })
            // httpGet("/course-info/list",
            //     {
            //         typeParentId: 3
            //     }
            //     , (res) => {
            //         this.newProfess = res.data.records;
            //     }, (err) => {
            //         // console.log(err)
            //         throw err
            //     })
        },
        goProfessYe() {
            this.$router.push({ name: 'CourseCenter' })
        },
        goElectiveYe() {
            this.$router.push({ name: 'CourseCenter' })
        },
        toBulletinMore() {
            this.$router.push({ name: 'BulletinMore' })
        },
        goCourses(item) {
            // console.log(item)
            this.$router.push({
                name: 'CourseChoosing',
                query: {
                    id: item.id,
                    name: item.name,
                    data: item
                }
            })
        },
        goProfess(item) {
            this.$router.push({
                name: 'CourseChoosing',
                query: {
                    id: item.id
                }
            })
        },
        goNotice(item) {
            this.$router.push({ name: 'BulletinDetails', params: { noticeId: item.id } })
        },
        goPolice(item) {
            // console.log(item.id)
            this.$router.push({
                name: 'policyDetails',
                query: {
                    id: item.id
                }
            })
        },
        // goNotice(item) {
        //     // this.$router.push('/policyDetails')
        //     this.$router.push({
        //       name:'/policyDetails',
        //       query:{
        //         id: item.id
        //       }
        //     })
        // },
        goPoliceYe(data) {
            // console.log(data)
            this.$router.push({
                name: 'policy',
                query: {
                    data: data
                }
            })
        },
        goHelperYe() {
            this.$router.push({ name: 'HotIssue' })
        },
    },
}
    ;
</script>

<style lang="scss" scoped>
.profess {
    width: 1260px;
    min-width: 1260px;
    margin: 0 auto 0 auto;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .profess-line {
        display: flex;
        justify-content: space-between;
        margin: 0 0 0.972rem 0;

        .profess-title {
            font-size: 1.435rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 2.25rem;
            display: flex;
            align-items: center;
        }
    }

    .profess-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .profess-content-div {
            // width: 580px;
            width: 49.1525%;
            margin-top: 1.25rem;
            height: 9.6875rem;
            display: flex;
            overflow: hidden;

            .profess-left {
                flex: 1;
                position: relative;

                img {
                    // width: 252px;
                    // height: 155px;
                    width: 100%;
                    height: 100%;
                }

                .title {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 76%;
                    font-size: 1.0625rem;
                    font-family: PingFang SC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 1.25rem;
                }
            }

            .profess-right {
                margin-left: 1.25rem;
                text-align: left;
                width: 53.103%;

                .profess-right-title {
                    font-size: 1rem;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #000000;
                    line-height: 1.625rem;
                    display: inline-block;
                    white-space: nowrap;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .profess-right-content {
                    font-size: 0.8125rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 1.25rem;
                    padding: 0.75rem 0 1.5rem 0;
                    border-bottom: solid 1px #E5E8ED;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .profess-right-hour {
                    font-size: 0.8125rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                    line-height: 1.25rem;
                    margin-top: 0.875rem;
                }
            }
        }
    }
}

.imgs-video-none {
    display: block;
}

#prompt-content {
    margin: 0 auto;
    opacity: 1;
    position: relative;
    box-shadow: 0 0.125rem 1.5rem 0 rgba(3, 27, 78, 0.12);
    cursor: pointer;
    width: 150px;
    height: 150px;
    padding: 12px;
    box-sizing: border-box;
    background-color: #fff;
}

#prompt-content>div {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    bottom: 30px;
    left: 39px;
    font-size: 1rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 1.1875rem;
    letter-spacing: 0.125rem;
}

.prompt-close {
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.125rem;
    position: absolute;
    top: -5%;
    right: -26%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: #fff;
    color: rgb(132, 132, 132);
    font-weight: 100;
}

.prompt-close:hover {
    background-color: #ff4040;
    color: #fff;
}

#prompt-content .prompt-hover {
    display: none;
    width: 8rem;
    height: 8rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 111;
    background: #0B3A76;
    border-radius: 0 0 0 0;
    opacity: 1;

    div:first-child {
        padding-top: 2rem;
        line-height: 1.25rem;
        font-size: 1rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }

    div:last-child {
        position: absolute;
        bottom: 1.125rem;
        left: 15%;
        font-size: 1rem;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.1875rem;
        letter-spacing: 0.125rem;
    }
}

#prompt {
    width: 8rem;
    height: 9.875rem;
    z-index: 1;
    position: absolute;
    top: 2.6875rem;
    left: 0.125rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.logo {
    width: 304px;
    height: 50px;
}

.mainSwiper {
    float: left;
    min-width: 1200px;

    img {
        width: 100%;
        height: 40rem;
    }
}

.container {
    z-index: 2000;
    // width: 1200px;
    // max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
}

.kong {
    width: 100%;
    height: 120px;
    background-color: #fff;
}

.header {
    height: 40px;
    line-height: 40px;
    background-color: #303030;
    color: aliceblue;
    font-size: 14px;
}

.header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
}

.header-top-right,
.header-top-right>div {
    display: flex;
    align-items: center;
}

.header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
}

.swiperStop {
    position: absolute;
    top: 120px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    background-color: #00000000;
    cursor: pointer;
}

.banner {
    position: relative;
    top: -82px;
    z-index: -1;
    display: flex;
    flex-direction: column;
    width: 100%;
    // min-height: 640px;
    /* background-color: #575752; */
}

.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.nav {
    width: 100%;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.14);
    color: #fff !important;
}

.nav-f {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: #fff;
    box-sizing: border-box;
    border: 1px solid rgba(255, 255, 255, 0.14);
    color: #333 !important;
}

.nav1 {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-center {
    width: 600px;
}

.nav-center .nav-list {
    /* margin: 0 auto;
    max-width: 600px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 78px;
}

.nav-center .nav-list>div {
    width: 600px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC, sans-serif;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    text-align: center;
    border-right: solid 0.2px #d0d0d0;
    text-shadow: 0px 6px 8px rgba(0, 0, 0, 0.8);
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
}

.nav-center .nav-list>div:last-child {
    border: none;
}

.nav-center .nav-list .nav-list--active {
    font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
    font-weight: bold;

    /*transition: all 0.3s;*/
    /*background-color: #FFE53B;*/
    /*background-image: linear-gradient(147deg, #FFE53B 0%, #FF2525 74%);*/
}

.nav-line {
    margin: 0 auto;
}

.nav-line>div {
    /*width: 32px;*/
    height: 4px;
    background-color: #ffffff;
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    border-radius: 2px;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
}

.nav-center-btn {
    /* height: 40px; */
    width: 122px;
    height: 40px;
    background: #1371e7;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
}

.nav-left {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 80px;
    /* line-height: 80px; */
}

.nav-left h2 {
    font-size: 24px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: 600;
    line-height: 76px;
    letter-spacing: 2px;
}

.nav-left span {
    width: 1px;
    height: 26px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
    margin: 0 15px;
    opacity: 0.2;
}

.steps {
    width: 1260px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: -590px;
    margin: auto;
    border-radius: 10px;
    height: 157px;
    background-color: #fff;
    //position: relative;
}

.steps-top {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.steps img {
    width: 48px;
    height: 48px;
}

.steps-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 157px;
}

.steps-num-left {
    z-index: 0;
    width: 90%;
    height: 28px;
    background-color: #0357bf;
    position: absolute;
    bottom: 0;
}

.steps-num-right {
    z-index: 0;
    width: 90%;
    height: 28px;
    background-color: #1574EC;
    position: absolute;
    bottom: 0;
    right: 0;
}

.steps-num {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    //background-color: #0357bf;
    position: relative;
}

.steps-num-item {
    width: 241px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #ffffff;
    font-size: 13px;
    line-height: 28px;
    border-radius: 20px;
    background: linear-gradient(90deg, #0357bf 0%, #1575ed 100%);
}

.courses {
    max-width: 1260px;
    min-width: 1260px;
    margin: 40px auto;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
}

.openCourses {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 323px;
    height: 468px;
    background-color: #00469E;
    box-shadow: 0px 2px 4px 0px rgba(3, 27, 78, 0.06);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #e5e8ed;
}

.ying {
    position: absolute;
    top: 0;
    width: 100%;
    height: 275px;
    background: linear-gradient(180deg, rgba(0, 70, 158, 0) 0%, #00469E 97%);
}

.openCourses img {
    display: block;
    width: 100%;
    height: 275px;
    /*background: linear-gradient(180deg, rgba(0,70,158,0) 0%, #00469E 97%);*/
}

.openCourses-all {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    /*background-color: #42b983;*/
}

.openCourses-content {
    width: 270px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 28px;
    box-sizing: border-box;
    background-color: #00469E;
    color: #fff;
}

.openCourses-content h3 {
    font-size: 16px;
    font-weight: 400;
}

.openCourses-content-time {
    //width: 72px;
    height: 20px;
    font-size: 11px;
    /*font-family: PingFang SC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #FFFFFF;
    line-height: 20px;
}

.openCourses-content-btn {
    text-align: center;
    line-height: 52px;
    width: 255px;
    height: 52px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    font-size: 16px;
    font-weight: 400;
    color: #1371E7;
}

.openCourses-content-item-all {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 260px;
    height: 93px;
    box-sizing: border-box;
    padding: 14px 22px;
    /*background-color: #99a9bf;*/
}

.openCourses-content-item {
    width: 70%;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    flex-wrap: wrap;
}

.openCourses-content-item-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 260px;
    height: 224px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 0px rgba(3, 27, 78, 0.06);
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    border: 1px solid #E5E8ED;
}

.openCourses-content-item-one img {
    display: block;
    width: 100%;
    height: 131px;
}

.openCourses-content-item-one h4 {
    width: 220px;
    height: 40px;
    font-size: 14px;
    /*font-family: PingFang SC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #000000;
    line-height: 20px;
}

.openCourses-content-item-one span {
    width: 73px;
    height: 20px;
    font-size: 12px;
    /*font-family: PingFang SC-Regular, PingFang SC;*/
    font-weight: 400;
    color: #666666;
    line-height: 20px;
}

//  选修

.elective-course {
    max-width: 1260px;
    min-width: 1260px;
    margin: 40px auto;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px 0px;
    box-sizing: border-box;
}

.link {
    margin-bottom: 3rem;
    box-sizing: border-box;

    .link-line {
        width: 1260px;
    }

    .content {
        height: 6rem;
        width: 1200px;
        display: flex;
        justify-content: space-between;
    }

    .content-div {
        cursor: pointer;
        width: 23.4746%;
        overflow: hidden;
        height: 6rem;
        background: #FFFFFF;
        border-radius: 10px;
        opacity: 1;
        border: 0.0625rem solid #E5E8ED;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 283px;
            height: 98px;
            object-fit: none;
        }
    }
}

.helper {
    background-image: url("~@/assets/home/bg-helper.png");
    opacity: 1;
    padding: 20px 0 0 0;
}

.elective-line {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0px 0 20px 0;
    display: flex;
    justify-content: space-between;
}

.home-market-section {
    width: 1200px;
    margin: 0 auto;
}

.link-line,
.helper-line {
    width: 1200px;
    margin: 20px auto 10px;
    display: flex;
    justify-content: space-between;
}

.helper-content {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 24px auto 0 auto
}

.helper-content img {
    width: 49%;
}

.elective-title,
.link-title,
.helper-title,
.profess-title {
    font-size: 1.4375rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 2.25rem;
    display: flex;
    align-items: center;
}

.link-title::before,
.helper-title::before {
    content: "";
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    background-size: 100% 100%;
    margin-right: 0.75rem;
}

.link-title::before {
    background: url('~@/assets/home/icon-link.png');
}

.helper-title::before {
    background: url('~@/assets/home/icon-helper.png');
}

//.elective-title::before, .link-title::before, .helper-title::before, .notice-title::before, .police-title::before, .profess-title::before {
//  content: "";
//  display: inline-block;
//  width: 1.75rem;
//  height: 1.75rem;
//  background-size: 100% 100%;
//  margin-right: 0.75rem;
//}
//
//.elective-title1::before, .link-title::before, .helper-title::before, .notice-title::before, .police-title::before, .profess-title::before {
//  content: "";
//  display: inline-block;
//  width: 1.75rem;
//  height: 1.75rem;
//  background-size: 100% 100%;
//  margin-right: 0.75rem;
//}


.elective-more,
.helper-more,
.notice-more,
.police-more,
.profess-more {
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 2.25rem;
}

.helper-more {
    color: #FFFFFF;
    cursor: pointer;
}

.message {
    width: 1260px;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    background-color: #fff;
    display: flex;
    margin: 40px auto;
    justify-content: space-between;

    .notice {
        width: 30.7627%;
        padding: 0 10px 20px 10px;

        .notice-line {
            display: flex;
            justify-content: space-between;

            .notice-title {
                font-size: 1.4375rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 2.25rem;
                display: flex;
                align-items: center;
            }
        }

        .notice-content,
        .police-content {
            text-align: left;
            margin: 1.5625rem auto 0.5rem auto;
            border-bottom: solid 0.0625rem #E5E8ED;

            .notice-content-title,
            .police-content-title {
                display: inline-block;
                white-space: nowrap;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.25rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #1371E7;
                line-height: 1.875rem;
            }

            .notice-content-item,
            .police-content-item {
                font-size: 0.8125rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 1.5rem;
                height: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .notice-content-bottom,
            .police-content-bottom {
                font-size: 0.75rem;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 1.5rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1.625px;
                margin-top: 0.5rem;

                .notice-content-time {}

                .police-content-time {}

                .notice-content-l,
                .police-content-l {
                    width: 0.0625rem;
                    height: 0.5rem;
                    background: #D9D9D9;
                    display: flex;
                    margin: 0 0.5rem;
                }

                .notice-content-person,
                .police-content-person {
                    //display: inline-block;
                    display: flex;
                    align-items: center;
                }

                .notice-content-time::before,
                .notice-content-person::before,
                .police-content-time::before,
                .police-content-person::before {
                    content: "";
                    display: inline-block;
                    width: 1rem;
                    height: 1rem;
                    background: url('~@/assets/home/icon-date.png');
                    background-size: 100% 100%;
                    margin-right: 0.5rem;
                }

                .notice-content-person::before {
                    background: url('~@/assets/home/icon-eye.png');
                }
            }
        }

        .notice-item,
        .police-item {
            text-align: left;

            ul {
                list-style-position: inside;

                li {
                    border-bottom: solid 0.0625rem #E5E8ED;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    overflow: hidden;
                    height: 3.0625rem;
                    line-height: 3.0625rem;
                    font-size: 0.875rem;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                }
            }
        }

    }

    .police {
        width: 1200px;
        padding: 0px 30px;

        .police-line {
            display: flex;
            justify-content: space-between;

            .police-title {
                font-size: 1.4375rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 2.25rem;
                display: flex;
                align-items: center;
            }
        }

        .police-two-div {
            display: flex;
            justify-content: space-between;

            .police-left {
                padding-left: 1rem;
                width: 49.12%;

                .police-content {
                    text-align: left;
                    margin: 1.5625rem auto 0.5rem auto;

                    .police-content-title {
                        display: inline-block;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 1.25rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1371E7;
                        line-height: 1.875rem;
                    }

                    .police-content-item {
                        font-size: 0.8125rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 1.5rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }

                    .police-content-bottom {
                        font-size: 0.75rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 1.5rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1.625rem;
                        margin-top: 0.5rem;

                        .police-content-time {
                            //display: inline-block;
                            display: flex;
                            align-items: center;
                        }

                        .police-content-l {
                            width: 0.0625rem;
                            height: 0.5rem;
                            background: #D9D9D9;
                            display: flex;
                            margin: 0 0.5rem;
                        }

                        .police-content-person {
                            display: flex;
                            align-items: center;
                        }

                        .police-content-time::before,
                        .police-content-person::before {
                            content: "";
                            display: inline-block;
                            width: 1rem;
                            height: 1rem;
                            background: url('~@/assets/home/icon-date.png');
                            background-size: 100% 100%;
                            margin-right: 0.5rem;
                        }

                        .police-content-person::before {
                            background: url('~@/assets/home/icon-eye.png');
                        }
                    }
                }

                .police-item {
                    text-align: left;

                    ul {
                        list-style-position: inside;

                        li {
                            border-bottom: solid 0.0625rem #E5E8ED;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow: ellipsis;
                            overflow: hidden;
                            height: 3.0625rem;
                            line-height: 3.0625rem;
                            font-size: 0.875rem;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #000000;
                        }
                    }
                }
            }

            .police-right {
                background: #F5F7FA;
                margin-top: 1.5625rem;
                // width: 320px;
                width: 43.3%;
                // height: 21.1875rem;
                border-radius: 10px;
                opacity: 1;
                border: 0.0625rem solid #E5E8ED;

                img {
                    width: 100%;
                }

                .police-right-content {
                    margin: 1.25rem 1.5625rem 0.9375rem 1.5625rem;
                    text-align: left;

                    .address {
                        font-size: 0.8125rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 1.5rem;
                        margin-top: 0.75rem;
                        margin-bottom: 0.75rem;
                    }

                    .title {
                        font-size: 1.1875rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #000000;
                        line-height: 1.875rem;
                    }

                    .more {
                        font-size: 0.8125rem;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #00469E;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }
}

.bottom {
    width: 100%;
    height: 2.75rem;
    background: #181B20;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.75rem;

    .bottom-content {
        width: 61.458%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .bottom-right {
        display: flex;
        align-items: center;
    }

    .bottom-right::before {
        content: "";
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        background: url('~@/assets/img/pre/bradge.png');
        background-size: 100% 100%;
        margin-right: 3px;
    }
}

.footer {
    padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

    .footer-content {
        width: 61.458%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto 1.5rem auto;
        padding: 2rem 0 0 0;
        align-items: center;

        .footer-top-line-1 {
            width: inherit;
            height: 0;
            opacity: 0.4;
            border: 0.0625rem solid;
            border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
        }

        .footer-top-line-2 {
            width: inherit;
            height: 0;
            opacity: 0.4;
            border: 0.0625rem solid;
            border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
            transform: rotate(180deg);
        }

        .footer-top-line-font {
            margin: 0 1.1%;
            font-size: 0.75rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.47);
            line-height: 1.25rem;
            width: 70%;
        }

        @media screen and (max-width: 1539px) {
            .footer-top-line-font {
                width: 120%;
            }
        }

        @media screen and (max-width: 1400px) {
            .footer-top-line-font {
                width: 130%;
            }
        }
    }

    .footer-detail {
        width: 61.458%;
        //background-color: red;
        // display: flex;
        justify-content: space-between;
        margin: 0 auto;
        text-align: left;
        font-size: 0.875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.25rem;
        align-items: center;

        .line {
            width: 1px;
            height: 0.875rem;
            background: #FFFFFF;
            opacity: 0.2;
        }

        .code-logo {
            width: 35.87%;
        }

        .code-img {
            // width: 100%;
            height: 5.9375rem;
        }

        ul {
            list-style-type: none;
            margin-top: 1.75rem;
            display: flex;

            justify-content: space-between;

        }
    }
}

.news-data {
    position: fixed;
    top: 0px;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
    z-index: 9999999;
    background-color: rgba(40, 40, 40, 0.8);
    text-align: center;

    .news-shade {
        width: 600px;
        min-height: 300px;
        max-height: 80vh;
        background-color: #fff;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        padding: 50px;
        box-sizing: border-box;

        .news-main {
            height: 100%;
            max-height: 70vh;
            overflow: auto;
        }
    }
}

.link {
    width: 1260px;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    margin: auto;
    margin-bottom: 3rem;

    .content {
        height: 6rem;
        // width: 61.458%;
        margin: 1.75rem auto 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .content-div {
        width: 23.4746%;
        height: 6rem;
        background: #FFFFFF;
        border-radius: 10px;
        opacity: 1;
        border: 0.0625rem solid #E5E8ED;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            border-radius: 10px;
            // width: 80.4746%;
        }

        span {
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 24px;
            display: block;
            position: absolute;
            left: 0px;
        }
    }
}

.helper {
    background-image: url("~@/assets/home/bg-helper.png");
    height: 18.0625rem;
    opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
