import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import router from "@/router";
import store from "@/store";
import '@/assets/css/botton/button.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Vuex from 'vuex';

import 'swiper/dist/css/swiper.css';
// import 'swiper-bundle.min.css';
import 'element-ui/lib/theme-chalk/index.css';
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

import myDialog from '@/components/funCom/dialog.vue'

import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;

// import { Icon } from 'vant';
//cookies配置
Vue.component('my-dialog', myDialog);
//关闭vue生产提示
Vue.config.productionTip = false

//注册插件 element-ui
Vue.use(ElementUI);
Vue.use(VueDPlayer);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(Vuex);
// Vue.use(Icon);

// localStorage.getItem("")


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

import {httpNPPost} from "@/utils/httpRequest";

// 刷新token
setInterval(()=>{
  if(localStorage.getItem("oatoken")){
    httpNPPost("/app/app-user/reToken",
        (res)=>{
          console.log(res);
          localStorage.setItem("tokenPass",res.data.tokenPass)
          localStorage.setItem("oatoken",res.data.token)
        },(err)=>{
          // console.log(err)
        })
  }
},2*60*60*1000)
