import axios from "axios";
import store from "@/store";
import ElementUI from "element-ui";
import router from "@/router";

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    // 发送请求之前做什么
    // console.log(this.$cookies.get("oatoken"))
    // 请求头带上token
    config.headers["token"] = localStorage.getItem("oatoken") || "";
    return config;
  },
  (error) => {
    // 对请求错误做什么
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (response.data && response.data.code === 302) {
      // 302, token失效
      // 登陆

      localStorage.clear();
      // this.$message({
      //     message: '请先登录',
      //     type: 'error'
      //   })
      if(router.history.pending){
        ElementUI.Message.error("请先登录");
      }
      router.push("/login");
      // console.log(router.history.pending.name);
      // console.log(router.currentRoute.path);
    //   debugger
      // router.replace({ name: '' });
      // location.reload();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
const adornUrl = (actionName) => {
  return "/api" + actionName;
  // return actionName;
};

/**
 * http ajax封装
 * @param url url
 * @param method 提交类型
 * @param params 提交参数
 * @param data 提交数据
 * @param success 成功处理函数 200为成功
 * @param error 失败处理函数 非200为失败
 */
const httpAjax = (url, method, params, data, success, error) => {
  http({
    url: adornUrl(url),
    method: method,
    params: params,
    data: data,
  }).then(({ data }) => {
    if (data.code === 200) {
      if (success && success.constructor === Function) {
        success(data);
      }
    } else {
      if (error && error.constructor === Function) {
        error(data);
      }
    }
  });
};

const httpGet = (url, params, success, error) => {
  httpAjax(url, "get", params, null, success, error);
};

const httpNPGet = (url, success, error) => {
  httpGet(url, null, success, error);
};

const httpPost = (url, data, success, error) => {
  httpAjax(url, "post", null, data, success, error);
};

const httpNPPost = (url, success, error) => {
  httpPost(url, null, success, error);
};

export { adornUrl, httpNPPost, httpNPGet, httpAjax, httpPost, httpGet };
