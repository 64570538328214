<template>
    <div ref="prompt" @click="click" @mouseover="changePosFlag=false" @mouseout="mouseout"  style="position: absolute;padding: 12px;background-color: #fff;" :style="{'z-index': 99999 + index}">
        <img :style="{ width: shiftData.width ? shiftData.width + 'px' : '240px', height: shiftData.height ? shiftData.height + 'px' : '150px' }"
            :src="shiftData.imgUrl" alt="">
            <span class="prompt-close" @click.stop="close">
                <i class="el-icon-close"></i>
            </span>
            <!-- <button @click.stop="close">关闭</button> -->
    </div>
</template>
 
<script>
import { log } from 'console';

export default {
    name: "ShiftBox",
    props: {
        shiftData: {
            type: Object,
            // 对象或数组默认值必须从一个工厂函数获取
            default: () => new Object(),
            required: true,
        },
        index: {
            type: Number,
            default: 0,
            required: true
        }
    },
    data() {
        return {
            xPos: 300,
            yPos: 100,
            xin: true,
            yin: true,
            step: 1,
            delay: 20,
            height: 0,
            heightSet: 0,
            widthSet: 0,
            yon: 0,
            xon: 0,
            changePosFlag: true,
        }
    },
    mounted() {
        //屏宽
        let width = document.documentElement.clientWidth;
        //屏高
        let height = document.documentElement.clientHeight;
        this.xPos = Math.random() * width;
        this.yPos = Math.random() * height;
        this.step = Math.random() + 1;
        this.xon = Math.random() > 0.5 ? 1 : 0;
        this.yon = Math.random() > 0.5 ? 0 : 1;
        requestAnimationFrame(this.changePos);
    },
    destroyed() {
        this.changePosFlag = false;
    },
    methods: {
        // 位移
        changePos() {            
            if (this.changePosFlag) {
                requestAnimationFrame(this.changePos);
            } else {
                return;
            }           
            //屏宽
            let width = document.documentElement.clientWidth;
            //屏高
            let height = document.documentElement.clientHeight;
            //获取元素高度
            this.heightSet = this.$refs.prompt.clientHeight;
            // console.log(this.$refs.prompt)
            //获取元素宽度
            this.widthSet = this.$refs.prompt.clientWidth;


            // 滚动部分跟随屏幕滚动
            // this.$refs.prompt.style.left = (this.xPos + document.body.scrollLeft + document.documentElement.scrollLeft) + "px";
            // this.$refs.prompt.style.top = (this.yPos + document.body.scrollTop + document.documentElement.scrollTop) + "px";

            // 滚动部分不随屏幕滚动
            this.$refs.prompt.style.left =
                this.xPos + document.body.scrollLeft + "px";
            this.$refs.prompt.style.top = this.yPos + document.body.scrollTop + "px";
            if (this.yon) {
                this.yPos = this.yPos + this.step;
            } else {
                this.yPos = this.yPos - this.step;
            }
            if (this.yPos < 0) {
                this.yon = 1;
                this.yPos = 0;
            }
            if (this.yPos >= height - this.heightSet) {
                this.yon = 0;
                this.yPos = height - this.heightSet;
            }

            if (this.xon) {
                this.xPos = this.xPos + this.step;
            } else {
                this.xPos = this.xPos - this.step;
            }
            if (this.xPos < 0) {
                this.xon = 1;
                this.xPos = 0;
            }
            if (this.xPos >= width - this.widthSet) {
                this.xon = 0;
                this.xPos = width - this.widthSet;
            }
        },
        mouseout() {
            this.changePosFlag = true;
            requestAnimationFrame(this.changePos);
        },
        click() {
            window.open(this.shiftData.toUrl);
        },
        close(e) {
            // console.log(this.shiftData)
            this.$emit("update:isShow", false);
        }
    }
}
</script>
 
<style scoped lang='scss'>
.prompt-close {
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.125rem;
    position: absolute;
    top: -5%;
    right: -4%;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-color: #fff;
    color: rgb(132, 132, 132);
    font-weight: 100;
}

.prompt-close:hover {
    background-color: #ff4040;
    color: #fff;
}
</style>